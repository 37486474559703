import AboutUsInfo from './footer-components/about-us-info'
import ContactInfo from './footer-components/contact-info'
import FooterCopyrightAndLanguage from './footer-components/footer-copyright-and-language'
import PaymentMethod from './footer-components/payment-method'
import PlentyAndTagline from './footer-components/plenty-and-tagline'
import PolicyLinks from './footer-components/policy-links'
import QuickHelpLinks from './footer-components/quick-help-links'
import SocialMediaIconBundle from './footer-components/social-media-icon-bundle'

export function Footer() {
	return (
		<footer className=" m-0 mx-auto">
			<div className="flex flex-col justify-between text-black-90">
				<div className="bg-beige-50 bg-ampersandFooter bg-center bg-no-repeat md:bg-desktop-footer ">
					<div className="mx-auto flex flex-col justify-start gap-8 px-4 pt-14 md:flex-row md:justify-between md:space-x-8 md:px-[var(--plenty-padding)]">
						<PlentyAndTagline />
						<AboutUsInfo />
						<QuickHelpLinks />
						<ContactInfo />
						<SocialMediaIconBundle />
					</div>
					<PaymentMethod />
				</div>
				<div className="flex flex-col justify-between gap-4 px-4 py-4 md:flex-row md:px-[var(--plenty-padding)] md:py-4">
					<FooterCopyrightAndLanguage />
					<PolicyLinks />
				</div>
			</div>
		</footer>
	)
}
